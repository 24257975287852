/*
 * @Author: szs
 * @Date: 2021-06-07 15:34:41
 * @LastEditTime: 2021-07-14 15:35:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \qianyue\src\components\company\feedback.js
 */
let feedbackDomain = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

  /**
  * 获取所有意见反馈
  * @param {any} success
  * @param {any} error
  */
    this.AllFeedback =function(success,error)
    {

        var url =ServiceBaseAddress +'/api/feedback';
        tokenClient.Get(url,true,null,null,
            function(data){
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            },error);
    }
        /**
     * 新增意见反馈
     * @param {any} type 反馈类型
     * @param {any} title 简单描述
     * @param {any} content 详细描述
     * @param {any} atta 附件图片
     * @param {any} phone 联系电话
     * @param {any} success
     * @param {any} error
     */
         this.AddFeedback = function (type, title, content,atta, phone,success, error) {
            var url = ServiceBaseAddress + '/api/Feedback';
            var param = {
                type: type,
                title: title,
                content: content,
                atta: atta,
                phone: phone,
            };
            TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data);
                        success(jsonObj);
                    }
                }, error);
        }
        //判断手机号码格式是否正确

}
export { feedbackDomain }
