<template>
    <div class="feedback xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
            </div>
        </div>
        <div class="feedback-wrap">
            <h3>请留下您宝贵的<span>意见建议</span></h3>
            <el-form ref="AddFeedbackForm" :model="feedback" label-width="80px" :rules='rules'>
                <el-form-item label="反馈类型">
                    <el-radio-group v-model="feedback.type">
                        <el-radio label="系统问题"></el-radio>
                        <el-radio label="意见建议"></el-radio>
                        <el-radio label="使用问题"></el-radio>
                        <el-radio label="其他"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="简单描述" prop='title'>
                    <el-input ref="title" v-model="feedback.title"></el-input>
                </el-form-item>
                <el-form-item label="详细描述" prop='content'>
                    <el-input type="textarea" v-model="feedback.content"></el-input>
                </el-form-item>
                <el-form-item label="上传图片">
                    <el-upload
                            :headers="headers"
                            :action="FileUploadUrl"
                            list-type="picture-card"
                            :limit="5"
                            multiple
                            :on-preview="handleImagePreview"
                            :on-remove="handleImageRemove"
                            :on-success="handleImageUpload"
                            :file-list="ImageContentList">
                        <i class="el-icon-plus"></i>
                        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，最多上传5张，单张大小不超过1M</div>
                    </el-upload>
                    <el-dialog :visible.sync="Image.dialogVisible">
                        <img width="100%" :src="Image.dialogImageUrl" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item label="联系电话" prop='phone'>
                    <el-input ref="phone" v-model="feedback.phone"></el-input>
                </el-form-item>
                <el-form-item style="text-align: right;">
                    <el-button type="primary" v-on:click="SaveFeedbackform('AddFeedbackForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>
<script>

    import ftCom from '../../components/footer';
    import nhCom from "../../components/navheader";
    import rtBar from "../../components/right";
    import { feedbackDomain } from '../../components/company/feedback';
    import storage from "../../utils/customstorage";
    export default {
        name: "feedback",
        inject:["reload"],
        components: {
            ftCom,
            nhCom,
            rtBar
        },
        computed: {
            headers() {
                return {
                    "Authorization": 'Bearer' + ' ' + storage.getItem("token")
                }
            }
        },
        data() {
            var feedbackTemp = new feedbackDomain(this.TokenClient, this.Services.Authorization)
            // console.log(Storage.getItem("token"))
            return {
                feedback: {
                    type: '意见建议',
                    title: '',
                    content: '',
                    atta:'',
                    phone:''
                },
                feedbackDomain:feedbackTemp,
                disabled: false,
                Image: {
                    //单击图片上传控件中的图片时，放大显示
                    dialogVisible: false,
                    dialogImageUrl: '',
                },
                ImageContent: [],//图片列表
                ImageContentList: [],//图片上传控件加载的数据
                FileUploadUrl:`${this.Services.Authorization}/api/File/upload`,
                rules: {
                    title: [
                        { required: true, message: '请填写简单描述', trigger: 'blur' },
                    ],
                    phone: [
                        { required: true, message: '请输入联系电话', trigger: 'blur' },
                        { pattern:/^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur"}
                    ],
                    content: [
                        { required: true,  message: '请填写详细描述', trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            handleImageUpload(response, file, fileList) {
                var _this = this;
                response.forEach(function (file) {
                    var imgarr = {
                        "filePath": file.completeFilePath,
                        "fileName": file.fileName,
                        "fileExt": file.fileExtension,
                    }

                    _this.ImageContent.push(imgarr);
                });
                console.log(_this.ImageContent)
            },
            handleImagePreview(file) {
                this.Image.dialogImageUrl = file.url;
                this.Image.dialogVisible = true;
            },
            handleImageRemove(file, fileList) {
                var _this = this;
                const filePath = file.response[0].completeFilePath;
                //2.从pics数组中找到图片对应的索引值
                //形参‘x’，是数组pic里的每一项
                const i = _this.ImageContent.findIndex(x =>
                    x.filePath == filePath);
                //调用数组的splice方法，把图片的信息对象从pics数组中移除
                _this.ImageContent.splice(i,1);
            },

            SaveFeedbackform(formName){
                var _this = this;
                var item = _this.feedback
                // if(item.title =="" || item.content ==""){
                //     this.$alert('请填写问题描述！', '提示', {
                //     confirmButtonText: '确定'});
                //     this.$refs["title"].focus();
                //     return false;
                // }
                // if(item.phone =="")
                // {
                //     _this.$alert('请填写联系电话！', '提示', {
                //     confirmButtonText: '确定'});
                //     _this.$refs["phone"].focus();
                //     return false;
                // }

                _this.$refs[formName].validate((valid) => {
                    if (valid) {
                        _this.feedbackDomain.AddFeedback(item.type, item.title, item.content,_this.ImageContent, item.phone, function (data) {
                                _this.$alert('提交成功！', '提示', {confirmButtonText: '确定'});
                                _this.$refs[formName].resetFields();
                                _this.ImageContentList = [];
                            },
                            function (err) {
                                console.log(err);
                            }
                        )
                    }else {
                        console.log("验证未通过");
                        return false;
                    }
                 })



            },
            getUuid(){
                 var len = 32;//32长度
                var radix = 16;//16进制
                var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
                var uuid = [], i;
                radix = radix || chars.length;
                if (len) {
                    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
                } else {
                    var r;
                    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
                    uuid[14] = '4';
                    for (i = 0; i < 36; i++) {
                        if (!uuid[i]) {
                            r = 0 | Math.random() * 16;
                            uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
                        }
                    }
                }
                return uuid.join('');
            }
        }
    }
</script>

<style scoped>
    .feedback{
        background: #f4f4f4;
    }
    .banner {
        width: 100%;
        height: 160px;
        background: url("../../assets/img/news_banner.jpg")center no-repeat;
    }
    .feedback-wrap{
        width: 950px;
        padding: 25px;
        margin: -60px auto 60px;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid #e5e5e5;
    }
    .feedback-wrap h3{
        font-size: 26px;
        color: #333333;
    }
    .feedback-wrap h3 span{
        color: #1fb7a6;
    }
</style>
